<template>
  <v-data-table
    disable-sort
    :headers="headers"
    :items="items"
    :loading="loading"
    :server-items-length="count"
    :items-per-page="queryParams.pageSize"
    :page="queryParams.page"
    @update:options="pagination"
    :footer-props="{
      itemsPerPageOptions: [5,10,30,50]
    }"
  >
    <template #item.profilePhotoUrl="{item}">
      <v-img :src="item.profilePhotoUrl" width="30" height="30" />
    </template>
    <template #item.operate="{item}">
      <a class="operate-btn" @click="unUser(item)">禁用用户</a>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      queryParams: {
        page: 1,
        pageSize: 10
      },
      count: 0,
      loading: false
    }
  },
  computed: {
    headers() {
      return [
        { text: '昵称', value: 'nickname' },
        { text: '性别', value: 'gender' },
        { text: '用户手机号', value: 'phone' },
        { text: '用户邮箱', value: 'email' },
        { text: '用户头像', value: 'profilePhotoUrl' },
        { text: '操作', value: 'operate', width: 100 }
      ]
    }
  },
  methods: {
    pagination(e) {
      const { itemsPerPage: pageSize, page } = e
      Object.assign(this.queryParams, {
        pageSize,
        page
      })
      this.loadApp()
    },
    async loadApp() {
      this.loading = true
      const res = await this.$post('/tAdmin/userList', this.queryParams, false)
      this.loading = false
      if (res.code !== 200) return
      this.count = res.count
      this.items = res.data.map((v) => {
        const paasMember = v.paasMember
        return {
          nickname: paasMember.nickname ?? '--',
          gender: paasMember.gender ?? 'Unknown',
          phone: paasMember.phone ?? '--',
          email: paasMember.email ?? '--',
          profilePhotoUrl: paasMember.profilePhotoUrl ?? '--'
        }
      })
      console.log(this.items)
    },
    async unUser(item) {
      this.$cux.asyncOperation({
        icon: 'warning',
        text: '确定禁用该用户吗?',
        preConfirm: async () => {
          const res = await this.$post('/tAdmin/banUser', { memberId: item.memberId })
          const isSuccess = res.code === 200
          this.$cux
            .alert({
              text: isSuccess ? '禁用成功' : '禁用失败',
              icon: isSuccess ? 'success' : 'error',
              timer: 1000
            })
            .then(() => {
              if (isSuccess) {
                this.loadApp()
              }
            })
        }
      })
    }
  }
}
</script>
